exports.components = {
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-posts-2013-03-21-htaccess-snippets-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2013-03-21/htaccess-snippets.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2013-03-21-htaccess-snippets-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2013-04-05-inicializacao-basica-de-plugins-jquery-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2013-04-05/inicializacao-basica-de-plugins-jquery.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2013-04-05-inicializacao-basica-de-plugins-jquery-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2013-05-06-acesso-facil-a-classe-respect-validation-validator-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2013-05-06/acesso-facil-a-classe-respect-validation-validator.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2013-05-06-acesso-facil-a-classe-respect-validation-validator-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2013-07-19-um-metodo-mais-confiavel-para-serializar-closures-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2013-07-19/um-metodo-mais-confiavel-para-serializar-closures.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2013-07-19-um-metodo-mais-confiavel-para-serializar-closures-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2013-07-24-interpretando-urls-com-dom-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2013-07-24/interpretando-urls-com-dom.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2013-07-24-interpretando-urls-com-dom-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2014-04-10-a-forma-mais-para-habilitar-conexoes-ao-banco-de-dados-oracle-no-php-5-rodando-sobre-o-ubuntu-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2014-04-10/a-forma-mais---para-habilitar-conexoes-ao-banco-de-dados-oracle-no-php5-rodando-sobre-o-ubuntu.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2014-04-10-a-forma-mais-para-habilitar-conexoes-ao-banco-de-dados-oracle-no-php-5-rodando-sobre-o-ubuntu-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2014-07-21-higiene-e-seguranca-em-escritorios-de-ti-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2014-07-21/higiene-e-seguranca-em-escritorios-de-ti.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2014-07-21-higiene-e-seguranca-em-escritorios-de-ti-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2014-09-23-webbr-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2014-09-23/webbr.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2014-09-23-webbr-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2014-10-16-symfony-process-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2014-10-16/symfony-process.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2014-10-16-symfony-process-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2014-12-03-git-para-times-de-todos-os-tamanhos-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2014-12-03/git-para-times-de-todos-os-tamanhos.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2014-12-03-git-para-times-de-todos-os-tamanhos-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2015-06-06-como-instalar-e-usar-o-composer-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2015-06-06/como-instalar-e-usar-o-composer.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2015-06-06-como-instalar-e-usar-o-composer-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2015-08-14-instalando-o-composer-com-o-composer-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2015-08-14/instalando-o-composer-com-o-composer.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2015-08-14-instalando-o-composer-com-o-composer-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2020-05-23-dark-mode-nao-adaptativo-uma-escolha-puramente-estetica-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2020-05-23/dark-mode-nao-adaptativo-uma-escolha-puramente-estetica.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2020-05-23-dark-mode-nao-adaptativo-uma-escolha-puramente-estetica-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2020-05-23-uma-sugestao-para-simular-um-e-paper-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2020-05-23/uma-sugestao-para-simular-um-e-paper.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2020-05-23-uma-sugestao-para-simular-um-e-paper-md" */),
  "component---src-templates-post-tsx-content-file-path-posts-2020-05-24-notas-sobre-the-great-coffeescript-to-typescript-migration-of-2017-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/home/runner/work/tassoevan.github.io/tassoevan.github.io/posts/2020-05-24/notas-sobre-the-great-coffeescript-to-typescript-migration-of-2017.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-posts-2020-05-24-notas-sobre-the-great-coffeescript-to-typescript-migration-of-2017-md" */)
}

